<template>
  <f7-page class="videoview-page">
    <f7-navbar back-link="Back">
      <f7-nav-right>
        <div v-if="postData?.TotalView && postData?.TotalView > 0" class="counter">
          <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
          {{ $h.formatDisplayNumber(postData?.TotalView) }}
        </div>
      </f7-nav-right>
    </f7-navbar>

    <section class="videofull-container">
      <div v-if="postData?.PostVideo" class="video-wrapper">
        <video controls muted :poster="postData?.PostImage ? $h.getImage(postData?.PostImage, 'POST') : ''">
          <source :src="$h.getVideo(postData?.PostVideo, 'POST')" type="video/mp4" />
          {{ $t.getTranslation("LBL_BROWSER_NO_SUPPORT_VIDEO") }}
        </video>

        <div class="content">
          <h1 v-if="postData?.PostTitle">{{ $h.formatTrimString(postData?.PostTitle, 80) }}</h1>
          <p v-if="postData?.PostText">{{ $h.formatTrimString(postData?.PostText, 160) }}</p>
        </div>

        <div class="floating-actions">
          <f7-button fill large @click="showProducts">
            <font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width />
          </f7-button>
          <f7-button fill large @click="showComments">
            <font-awesome-icon :icon="['far', 'comments']" fixed-width />
          </f7-button>
          <f7-button fill large @click="otherActions">
            <font-awesome-icon :icon="['far', 'ellipsis-h']" fixed-width />
          </f7-button>
        </div>
      </div>
    </section>

    <f7-actions class="otherActions">
      <f7-actions-group v-if="postData?.IsSelfPost == 1">
        <f7-actions-button @click="editPost">{{ $t.getTranslation("LBL_EDIT") }}</f7-actions-button>
        <f7-actions-button color="red" @click="deletePost">{{ $t.getTranslation("LBL_DELETE") }}</f7-actions-button>
      </f7-actions-group>
      <f7-actions-group>
        <f7-actions-button @click="showShare">{{ $t.getTranslation("LBL_SHARE") }}</f7-actions-button>
        <!-- <f7-actions-button color="red" @click="showUserReport">{{ $t.getTranslation("LBL_REPORT") }}</f7-actions-button> -->
      </f7-actions-group>
    </f7-actions>

    <PostCommentComponent v-if="postData && postData.PostKey !== ''" ref="postCommentComponent" :post-data="postData" @updateTotalComment="updateTotalComment" />

    <PostProductComponent v-if="postData && postData.PostKey !== ''" ref="postProductComponent" :post-data="postData" />

    <ShareComponent
      v-if="postData && postData.PostKey !== ''"
      ref="shareComponent"
      :share-data="{
        type: 'POST',
        key: postData.PostKey,
        title: postData.PostText,
        description: postData.PostText,
        qoute: postData.PostText,
        hashtags: postData?.PostTagList,
        media: $h.getImage(postData.PostImage, 'POST'),
      }"
    />

    <UserReportComponent v-if="postData && postData.PostKey !== ''" ref="userReportComponent" :report-key="postData?.PostKey" type="POST" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, inject, defineAsyncComponent } from "vue";
import { f7 } from "framework7-vue";
import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";

// import PostCommentComponent from "@/components/PostCommentComponent.vue";
// import PostProductComponent from "@/components/PostProductComponent.vue";
// import ShareComponent from "@/components/ShareComponent.vue";
// import UserReportComponent from "@/components/UserReportComponent.vue";

const PostProductComponent = defineAsyncComponent(() => import(/* webpackChunkName: "post-product" */ /* webpackMode: "lazy" */ "@/components/PostProductComponent.vue"));
const PostCommentComponent = defineAsyncComponent(() => import(/* webpackChunkName: "post-comment" */ /* webpackMode: "lazy" */ "@/components/PostCommentComponent.vue"));
const ShareComponent = defineAsyncComponent(() => import(/* webpackChunkName: "share" */ /* webpackMode: "lazy" */ "@/components/ShareComponent.vue"));
const UserReportComponent = defineAsyncComponent(() => import(/* webpackChunkName: "user-report" */ /* webpackMode: "lazy" */ "@/components/UserReportComponent.vue"));

export default defineComponent({
  name: "VideoViewPage",
  components: {
    PostCommentComponent,
    PostProductComponent,
    ShareComponent,
    UserReportComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();
    
    const postData = ref(false);

    onMounted(() => {
      if (props?.f7route?.params?.key) getPostData(props?.f7route?.params?.key);
    });

    const getPostData = async (key) => {
      let ret = await get("/post/view", { PostKey: key, LanguageCode: $t.getLanguage() });
      postData.value = ret;

      increaseView();
    };

    const otherActions = () => {
      f7.sheet.get(".otherActions").open();
    };

    const onViewInfluencer = (userKey) => {
      props.f7router.navigate({ name: "influencerViewPage", params: { code: userKey } });
    };

    const followToggle = async (UserKey) => {
      if (!helpers.isUserLogin()) {
        props.f7router.navigate({ name: "loginPage" });
        return;
      }

      await post("/follow/toggle", { FollowKey: UserKey, Type: "USER" });
      postData.value.IsFollow = postData.value.IsFollow === 1 ? 0 : 1;
    };

    const likeToggle = async (PostKey) => {
      if (!helpers.isUserLogin()) {
        props.f7router.navigate({ name: "loginPage" });
        return;
      }

      await post("/post/like/toggle", { PostKey: PostKey });

      if (postData.value.IsLike === 1) {
        postData.value.IsLike = 0;
        postData.value.TotalLike--;
      } else {
        postData.value.IsLike = 1;
        postData.value.TotalLike++;
      }
    };

    const increaseView = async () => {
      let postKey = props?.f7route?.params?.key;
      store.dispatch("view/increase", { type: "POST", key: postKey });
    };

    const updateTotalComment = (count) => {
      postData.value.TotalComment = postData.value.TotalComment + count;
    };

    const editPost = () => {
      props.f7router.navigate({ name: "postUpdatePage", params: { key: postData?.value?.PostKey } });
    };

    const deletePost = () => {
      if (!helpers.isUserLogin()) {
        props.f7router.navigate({ name: "loginPage" });
        return;
      }

      helpers.createConfirmation({
        message: $t.getTranslation("LBL_CONFIRM_DELETE_POST"),
        confirm: async () => {
          await post("/post/delete", {
            PostKey: postData?.value?.PostKey,
          });

          props.f7router.back({ force: true });
        },
      });
    };

    const selectProduct = (data) => {
      store.dispatch("view/increase", { type: "POSTPRODUCT", key: data.PostProductId });
      props.f7router.navigate({ name: "productViewPage", params: { code: data.ProductKey } });
    };

    const onShowProducts = () => {
      f7.sheet.get(".product-sheet").open();
    };

    return {
      postData,
      otherActions,
      onViewInfluencer,
      followToggle,
      likeToggle,
      updateTotalComment,
      selectProduct,
      editPost,
      deletePost,
      onShowProducts,
    };
  },
  methods: {
    showComments() {
      this.$refs.postCommentComponent.showCommentSheet(this?.postData?.PostKey);
    },
    showProducts() {
      this.$refs.postProductComponent.showProductSheet();
    },
    showShare() {
      this.$refs.shareComponent.showShareSheet();
    },
    showUserReport() {
      this.$refs.userReportComponent.showUserReportPopup();
    },
  },
});
</script>
